<template>
  <div id="app" :class="[{ 'rtl': isRtl }, currentLanguage ]">
    <v-spinner :key="route"></v-spinner>
    <v-notification :key="route"></v-notification>
    <v-modal :key="route"></v-modal>
    <router-view></router-view>
  </div>
</template>

<script>
import '@/assets/stylus/app.styl'
import { mapActions, mapGetters } from 'vuex'
import { getCookie, setCookie, setTierSupport } from './utils/session'

import VSpinner from '@/components/Spinner.vue'
import VNotification from '@/components/Notification.vue'
import VModal from '@/components/Modal.vue'
import { when } from '@2l/utils'

import { isRestrictedDomain } from '@/lib/helpers'
import { useWindowSize } from 'vue-window-size'
import { tracker } from './plugins/vue-any'

const isExcludedPage = when({
  name: [
    'checkoutHoliday.index',
    'holidayPayment.index',
    'payment_required',
    'onboarding.index',
    'auth.index',
    'paywallSuccess.index',
    'paywallDownload.index',
    'paywallPromoV2.payment',
    'paywallPromoV2.start',
    'paywallPromoV2.index',
    'paywallV2.payment',
    'paywallV2.start',
    'paywallV2.index'
  ]
})

export default {
  /**
   * The name of the application.
   */
  name: 'ewa-tng',

  setup () {
    const { width } = useWindowSize()
    return {
      windowWidth: width
    }
  },

  /**
   * Component that could be used in template
   */
  components: {
    VNotification,
    VSpinner,
    VModal
  },

  data () {
    return {
      modalIsOpened: false
    }
  },
  computed: {
    ...mapGetters('account', {
      getUserId: 'getUserId',
      getBill: 'getBill',
      isRenewable: 'isRenewable',
      currentLanguage: 'getCurrentLanguage',
      isBillPending: 'isBillPending',
      getPlanType: 'getPlanType',
      getHasInstallSigns: 'getHasInstallSigns'
    }),
    route () {
      return this.$route
    },
    auth () {
      return this.$route.meta.auth
    },
    isRtl () {
      const rtlLangs = ['fa', 'he', 'ar']
      return rtlLangs.includes(this.currentLanguage)
    },
    isMobile () {
      return this.windowWidth < 768
    },
    isVisibleMobileAppModal () {
      const AVAILABLE_PAGES = ['roadmap', 'course.list', 'library.start']

      const lastShown = localStorage.getItem('lastModalShown')
      const today = new Date().toLocaleDateString()

      const isAvailablePage = AVAILABLE_PAGES.includes(this.$route.name)
      const isToday = lastShown === today
      const showModalForInstall = !isToday && !this.getHasInstallSigns
      const showModalForMobile = this.isMobile && !isToday

      return (showModalForInstall || showModalForMobile) &&
          tracker.getConfigStringValue('mobile_banner', '') === 'true' &&
          isAvailablePage
    }
  },

  watch: {
    auth: {
      handler (val) {
        if (!val) return

        if (!this.isVisibleMobileAppModal) {
          this.openModal()
        }
      },
      immediate: true
    },

    getUserId (id) {
      if (!id) { return }

      this.$setCustomerUserId(id)
    },

    '$route.name': function () {
      if (this.isVisibleMobileAppModal) {
        localStorage.setItem('lastModalShown', new Date().toLocaleDateString())
        this.setOptions({
          modalClass: 'modal-mobile-app'
        })
        this.open('MobileApp')
      }
    }
  },

  methods: {
    ...mapActions('modal', {
      open: 'open',
      setOptions: 'setOptions'
    }),
    ...mapActions('auth', ['check', 'logout']),
    ...mapActions('account', ['detectLocation']),
    openModal () {
      const promoPopupClosed = localStorage.getItem('promoPopupClosed')

      const shouldBeShown = !isRestrictedDomain &&
        !isExcludedPage(this.$route) &&
        !this.isRenewable &&
        this.getPlanType !== 'Unlimited' &&
        !this.isBillPending &&
        !promoPopupClosed

      if (shouldBeShown) {
        const c = getCookie('visited')
        const promoUser = localStorage.getItem('promoUser')
        const userCompletedLesson = localStorage.getItem('userCompletedLesson')

        if ((c === 'yes' || promoUser || userCompletedLesson) && !getCookie('2chCtrl')) {
          this.setOptions({
            modalClass: 'promo__modal'
          })
          this.open('promo')
          localStorage.setItem('showPromoHeader', 'true')
        }
      }
      setCookie('visited', 'yes', 365)
    }
  },

  async mounted () {
    await this.detectLocation()
    setTierSupport()
    await this.check()
  }
}

</script>

/* ============
 * Locale
 * ============
 *
 * For a multi-language application, you can
 * specify the languages you want to use here.
 */

import ar from './locale/ar.json'
import az from './locale/az.json'
import bg from './locale/bg.json'
import cs from './locale/cs.json'
import de from './locale/de.json'
import el from './locale/el.json'
import en from './locale/en.json'
import es from './locale/es.json'
import et from './locale/et.json'
import fa from './locale/fa.json'
import fi from './locale/fi.json'
import fr from './locale/fr.json'
import he from './locale/he.json'
import hi from './locale/hi_IN.json'
import hu from './locale/hu.json'
import hy from './locale/hy.json'
import hr from './locale/hr.json'
import id from './locale/id.json'
import it from './locale/it.json'
import ja from './locale/ja.json'
import ka from './locale/ka_GE.json'
import kk from './locale/kk.json'
import ko from './locale/ko.json'
import lv from './locale/lv.json'
import lt from './locale/lt.json'
import ms from './locale/ms.json'
import nl from './locale/nl.json'
import no from './locale/no.json'
import pl from './locale/pl_PL.json'
import pt from './locale/pt.json'
import ro from './locale/ro.json'
import ru from './locale/ru.json'
import sk from './locale/sk.json'
import sr from './locale/sr.json'
import sv from './locale/sv.json'
import th from './locale/th.json'
import tr from './locale/tr.json'
import uk from './locale/uk.json'
import vi from './locale/vi.json'
import zh from './locale/zh.json'

export const langList = ['ar', 'az', 'bg', 'cs', 'de', 'el', 'en', 'es', 'et', 'fa', 'fi', 'fr', 'he', 'hi', 'hu', 'hy', 'hr', 'id', 'it', 'ja', 'ka', 'kk', 'ko', 'lv', 'lt', 'ms', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sr', 'sv', 'th', 'tr', 'uk', 'vi', 'zh']
export default {
  ar,
  az,
  bg,
  cs,
  de,
  el,
  en,
  es,
  et,
  fa,
  fi,
  fr,
  he,
  hi,
  hu,
  hy,
  hr,
  id,
  it,
  ja,
  ka,
  kk,
  ko,
  lv,
  lt,
  ms,
  nl,
  no,
  pl,
  pt,
  ro,
  ru,
  sk,
  sr,
  sv,
  th,
  tr,
  uk,
  vi,
  zh
}

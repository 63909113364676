import { v4 as uuid } from 'uuid'
import * as Sentry from '@sentry/vue' // eslint-disable-line
import {
  extend,
  forEach,
  map,
  asArray,
  cleanUpObject,
  when,
  firstOf,
  G,
  all,
  concat,
  orNull,
  compose,
  includes,
  oneOf
} from '@2l/utils'
import { Tracker } from '@2l/any-web-sdk'
import * as Links from '@2l/any-web-sdk/src/links'
import * as adapters from '@2l/any-web-sdk/src/adapters'
import store from '@/store/index.js'
import * as u from '@2l/any-web-sdk/src/links/util'
import { throwOnTimeoutFunction, isTimeoutExceededError } from '@/lib/helpers'

const SUBSCRIBE = Symbol('Subscribe')
const PURCHASED = Symbol('Purchased')

export const tracker = Tracker.GetTrackerByMediaSource(u.param('utm_source'))

const needUrlParams = when([
  { eventName: 'landing', subtype: 'visited' },
  { eventName: 'subscriptions', subtype: 'visited' }
])

const getUtmSourceFallback = oneOf({
  facebook: includes('location.pathname', 'kickstart')
}, '')

const getReferrer = compose(firstOf(
  all(G('history.state.back'),
    concat(
      G('location.origin'),
      G('history.state.back'))
  ),
  G('document.referrer')
), orNull)

const getCurrentUrl = G('location.href')

export default class VueAnyPlugin {
  static install (app) {
    const queue = []
    const any = {
      LINKS: Links,
      ANALYTICS: tracker,
      STREAMS: {
        [adapters.AdjustAdapter.getStream()]: adapters.AdjustAdapter.getStream(),
        [adapters.YandexAdapter.getStream()]: adapters.YandexAdapter.getStream(),
        [adapters.TwitterAdapter.getStream()]: adapters.TwitterAdapter.getStream(),
        [adapters.FacebookAdapter.getStream()]: adapters.FacebookAdapter.getStream(),
        [adapters.GoogleAnalyticsAdapter.getStream()]: adapters.GoogleAnalyticsAdapter.getStream(),
        [adapters.CRMAdapter.getStream()]: adapters.CRMAdapter.getStream()
      }
    }

    let scheduled = 0

    if (!window.EWA) { window.EWA = any }

    switch (u.param('utm_source', getUtmSourceFallback(window)).toLowerCase()) {
      case 'oceanengine':
        tracker.listen('schema.adapter.ready.OCEANENGINE', waitForReady())
        break
      case 'facebook':
        tracker.listen('schema.adapter.ready.FACEBOOK', waitForReady())
        tracker.listen('schema.adapter.ready.ADJUST', waitForReady())
        break
      case 'twitter':
        tracker.listen('schema.adapter.ready.TWITTER', waitForReady())
        tracker.listen('schema.adapter.ready.ADJUST', waitForReady())
        break
      case 'google_ads':
        tracker.listen('schema.adapter.ready.ADJUST', waitForReady())
        tracker.listen('schema.adapter.ready.GOOGLE_ANALYTICS', waitForReady())
        break
      default:
        tracker.listen('schema.adapter.ready.ADJUST', waitForReady())
    }

    tracker.init().catch(Sentry.captureException)

    app.config.globalProperties.$trackStartTrail = trackStartTrail
    app.config.globalProperties.$trackCustomEvent = trackCustomEvent
    app.config.globalProperties.$trackCustomEvents = trackCustomEvents
    app.config.globalProperties.$setCustomerUserId = setCustomerUserId
    app.config.globalProperties.$lookUpForAdjustUrl = lookUpForAdjustUrl
    app.config.globalProperties.$trackPurchasedSubscription = trackPurchasedSubscription
    app.config.globalProperties.$triggerExperimentLayer = triggerLayer
    app.config.globalProperties.$getConfigStringValue = getConfigStringValue
    app.config.globalProperties.$getExternalId = () => tracker.external_id

    app.provide('trackCustomEvent', trackCustomEvent)
    app.provide('trackCustomEvents', trackCustomEvents)
    app.provide('lookUpForAdjustUrl', lookUpForAdjustUrl)
    app.provide('getConfigStringValue', getConfigStringValue)

    let previousEvent

    async function triggerLayer (layer, experimentId = '') {
      if (!layer) { return }

      const context = await getContext()

      for (const l of asArray(layer || [])) { await tracker.triggerLayer(l, context, experimentId) }
    }

    function waitForReady () {
      scheduled++

      return function () {
        scheduled--
        if (scheduled === 0) { return drain() }
      }
    }

    async function trackCustomEvent (eventName, params, adapters) {
      if (!tracker.initialized) { return queue.push({ eventName, params, adapters }) }

      await drain()

      const context = await getContext(eventName, params)

      if (previousEvent) { context.sourcePage = previousEvent }

      previousEvent = eventName

      if (!adapters || adapters.length === 0) { adapters = undefined }

      await new Promise((resolve) => {
        switch (eventName) {
          case SUBSCRIBE:
            return tracker
              .subscribe(extend(context, params), adapters)
              .on('success', resolve)
              .on('error', (err) => Sentry.captureException(err, context))
          case PURCHASED:
            return tracker
              .pay(extend(context, params), adapters)
              .on('success', resolve)
              .on('error', (err) => Sentry.captureException(err, context))
          default:
            return tracker
              .customEvent(eventName, extend(context, params), adapters)
              .on('success', resolve)
              .on('error', (err) => Sentry.captureException(err, context))
        }
      })
    }

    async function trackCustomEvents (events, timeout = 500) {
      try {
        await Promise.all([
          ...map(args => this.$trackCustomEvent(...args), events),
          throwOnTimeoutFunction(timeout)
        ])
      } catch (err) {
        if (!isTimeoutExceededError(err)) { Sentry.captureException(err) }
      }
    }

    async function trackStartTrail (revenue, currency, adapters) {
      return trackCustomEvent(SUBSCRIBE, { revenue, currency }, adapters)
    }

    async function trackPurchasedSubscription (revenue, currency, adapters) {
      return trackCustomEvent(PURCHASED, { revenue, currency }, adapters)
    }

    async function setCustomerUserId (id) {
      await tracker.setCustomerUserId(id)
    }

    async function lookUpForAdjustUrl (alias, urlParams = '') {
      const locationUrl = new URL(location.href)
      const urlRedirect = locationUrl.searchParams.get('redirect')
      const redirect = urlRedirect ? `redirect=${encodeURIComponent(urlRedirect)}` : ''

      const redirectParams = urlParams || redirect ||
        tracker.getConfigStringValue('redirect_appstore_url',
          'fallback=https://apps.apple.com/app/ewa-learn-english-spanish/id1200778841')

      const adjustUrl = any.LINKS.lookup(window.EWA.STREAMS.ADJUST, alias)

      if (redirectParams) {
        const paramsArray = redirectParams.split('&')

        forEach(item => {
          const keyParam = item.split('=')
          adjustUrl.searchParams.set(keyParam[0], decodeURIComponent(keyParam[1]))
        }, paramsArray)
      }

      return adjustUrl
    }

    async function drain () {
      console.log('[vue-any] start draining events')
      if (!queue.length) { return }

      const operations = map(({ eventName, params, adapters }) => {
        return trackCustomEvent(eventName, params, adapters)
      }, queue.splice(0, queue.length))

      return Promise.all(operations)
    }

    async function getContext (eventName = '', params = {}) {
      const { getters } = store
      const extra = {}

      if (needUrlParams({ eventName, subtype: params.subtype })) {
        extra.current_url = getCurrentUrl(window)
        extra.referrer = getReferrer(window)
      }

      return cleanUpObject(extend({
        paid: getters['account/isPremium'] ? 1 : 0,
        event_id: uuid(),
        loggedIn: getters['auth/isAuthenticated'] ? 1 : 0,
        activeProfile: getters['account/getActiveProfile'] || 'en',
        nativeLanguage: getters['account/getCurrentLanguage'],
        environment: __ENVIRONMENT__ || 'development',
        customerUserId: getters['account/getUserId'],
        customerEmail: getters['account/getEmail'],
        tierType: getters['offers/getTierType'],
        roadMapAllowed: getters['account/isRoadMapAllowed'],
        message_id: getters['offers/getMessageId'],
        campaign_name: getters['offers/getCampaignName'],
        bill_id: getters['account/getBillId']
      }, extra))
    }

    function getConfigStringValue (value, fallback) {
      return tracker.getConfigStringValue(value, fallback)
    }
  }
}

/* ============
 * Mutations for crm tiers experiment
 * ============
 *
 * The mutations that are available on the
 * crm tiers experiment
 */

import { G, has } from '@2l/utils'

import {
  SET_IS_CRM_TIER_EXPERIMENT,
  SET_IS_PLANS_FROM_QUERY,
  SET_PLAN_IDS,
  SET_QUERY_TIER_TYPE
} from '@/store/modules/crmTierExperiment/mutation-types'
import { CRM_EXPERIMENT_PAGES, CRM_EXPERIMENT_TIERS } from '@/store/modules/crmTierExperiment/constants'

export default {
  [SET_IS_PLANS_FROM_QUERY] (state, route) {
    state.isPlansFromQuery = has('query.planIds')(route)
  },
  [SET_QUERY_TIER_TYPE] (state, route) {
    state.queryTierType = G('query.tier_type')(route)
  },
  [SET_IS_CRM_TIER_EXPERIMENT] (state, payload) {
    state.isCrmTierExperiment = payload
  },
  [SET_PLAN_IDS] (state, { routePlanGroup, allPlanGroups, route }) {
    let planIds = null

    state.isCrmTierExperiment =
      CRM_EXPERIMENT_PAGES.some(page => route.path === page) &&
      (CRM_EXPERIMENT_TIERS.includes(state.queryTierType) || state.isPlansFromQuery)

    if (state.isCrmTierExperiment) {
      if (state.isPlansFromQuery) {
        planIds = route.query.planIds.split(',').slice(0, 2)
      } else if (state.queryTierType) {
        planIds = allPlanGroups[routePlanGroup][state.queryTierType]
      }
    }

    if (!planIds) {
      state.isCrmTierExperiment = false
    }

    state.planIds = planIds
  }
}

import store from '../store'
import { G } from '@2l/utils'
import { checkouts } from './checkouts'
import { landings } from './landings'

const registerModule = (name, path) => {
  return new Promise(resolve => {
    const state = G('_modules.root.state')(store)
    if (state && state[name]) { return resolve() }

    import(`@/store/modules/${path}/index.js`).then((module) => {
      store.registerModule(name, module.default)
      resolve()
    })
  })
}

/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */

export default [
  // Home
  {
    path: '/',
    name: 'home.index',
    component: () => import('@/pages/Landings/Main/Main'),
    meta: {
      experiment_layer: 'conversion',
      type: 'appewa_main'
    }
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('@/pages/About/Index.vue'),
    meta: {
      auth: false,
      type: 'about_page'
    }
  },

  {
    path: '/about-us',
    name: 'about.ru',
    component: () => import('@/pages/AboutRu/Index.vue'),
    meta: {
      auth: false,
      type: 'about_page'
    }
  },

  {
    path: '/support',
    name: 'support.message',
    component: () => import('@/pages/Support/Index.vue'),
    meta: {
      auth: false,
      type: 'support_page'
    }
  },

  // Courses
  {
    path: '/course',
    name: 'class.index',
    redirect: '/courses',
    component: () => import('@/pages/Courses/Index'),
    beforeEnter (to, from, next) {
      return new Promise(() => {
        registerModule('lesson', 'lesson').then(() => next())
      })
    },

    // If the user needs to be authenticated to view this page
    meta: {
      auth: true,
      title: 'Courses',
      experiment_layer: 'class'
    },

    children: [
      // Course
      {
        name: 'course.index',
        path: '/course/:courseId',
        // Course will be rendered inside Home's <router-view>
        // when /course/:id is matched
        component: () => import('@/pages/Courses/Course'),
        meta: {
          auth: true,
          title: 'Courses',
          experiment_layer: 'class'
        }
      }
    ]
  },

  {
    path: '/roadmap',
    name: 'roadmap',
    component: () => import('@/pages/RoadMap/Index'),
    beforeEnter (to, from, next) {
      const promise = store.getters['account/getUserId']
        ? new Promise(resolve => resolve(true))
        : store.dispatch('account/find')

      promise.then(() => {
        if (!store.getters['account/areCoursesAvailable']) { return next('/library/') }

        return next()
      })
    },
    meta: {
      auth: true,
      type: 'roadmap_page',
      title: 'Courses',
      experiment_layer: 'class'
    }
  },

  // CourseList
  {
    name: 'course.list',
    path: '/courses',
    component: () => import('@/pages/Courses/CourseList'),
    beforeEnter (to, from, next) {
      const promise = store.getters['account/getUserId']
        ? new Promise(resolve => resolve(true))
        : store.dispatch('account/find')

      promise.then(() => {
        if (!store.getters['account/areCoursesAvailable']) { return next('/library/') }
        if (!store.getters['account/isCoursesUser']) { return next('/roadmap/') }

        return new Promise(() => {
          registerModule('lesson', 'lesson').then(() => next())
        })
      })
    },
    meta: {
      auth: true,
      experiment_layer: 'class'
    }
  },

  {
    path: '/lesson/:lessonId',
    name: 'lesson.index',
    component: () => import('@/pages/Lesson/Index'),
    beforeEnter (to, from, next) {
      Promise.all([
        registerModule('lesson', 'lesson'),
        registerModule('progress', 'progress')
      ]).then(() => next())
    },

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      title: 'Lesson',
      experiment_layer: 'class'
    },

    children: [
      // Exercise
      {
        name: 'lesson.exercise',
        path: 'exercise/:id',
        // LessonExercise will be rendered inside Lesson's <router-view>
        // when /lesson/:lessonId/exercise/:id is matched
        component: () => import('@/pages/Lesson/Exercise')
      },

      // Lesson/FinalScreen
      {
        name: 'lesson.complete',
        path: 'finalize',
        // FinalScreen will be rendered inside Lesson's <router-view>
        // when /lesson/:lessonId/finalize is matched
        component: () => import('@/pages/Lesson/FinalScreen')
      }
    ]
  },

  // Account
  {
    path: '/account',
    name: 'account.index',
    component: () => import('@/pages/Account/Index'),

    // If the user needs to be authenticated to view this page.
    meta: {
      auth: true,
      title: 'Account',
      experiment_layer: 'class'
    },

    children: [
      // Account/PurchaseManagement
      {
        name: 'account.purchase',
        path: 'purchase',
        // PurchaseManagement will be rendered inside Account's <router-view>
        // when /account/purchase is matched
        component: () => import('@/pages/Account/PurchaseManagement'),
        meta: {
          auth: true,
          experiment_layer: 'class'
        }
      },
      // Account/ChangePassword
      {
        name: 'account.password',
        path: 'password',
        // ChangePassword will be rendered inside Account's <router-view>
        // when /account/password is matched
        component: () => import('@/pages/Account/ChangePassword'),

        meta: {
          auth: true,
          changeForm: true,
          experiment_layer: 'class'
        },

        children: [
        // Account/ChangePassword/success
          {
            name: 'account.password.success',
            path: 'success',

            component: () => import('@/pages/Account/ChangePassword')
          }
        ]
      },
      // Account/Support
      {
        name: 'account.support',
        path: 'support',
        meta: {
          auth: true,
          experiment_layer: 'class'
        },
        // Support will be rendered inside Account's <router-view>
        // when /account/support is matched
        component: () => import('@/pages/Account/Support'),

        children: [
        // Account/Support/success
          {
            name: 'account.support.success',
            path: 'success',

            component: () => import('@/pages/Account/Support'),
            meta: {
              auth: true,
              experiment_layer: 'class'
            }
          }
        ]
      },
      // Account/ChangeLanguage
      {
        name: 'account.language',
        path: 'language',
        // ChangeLanguage will be rendered inside Account's <router-view>
        // when /account/language is matched
        component: () => import('@/pages/Account/ChangeLanguage'),
        meta: {
          auth: true,
          experiment_layer: 'class'
        }
      }
    ]
  },

  // Reset
  {
    path: '/auth',
    name: 'auth.index',
    component: () => import('@/pages/Auth/Auth'),

    meta: {
      title: 'Sign in',
      auth: false,
      experiment_layer: 'conversion',
      type: 'auth_page'
    }
  },
  {

    path: '/auth/reset/:id',
    name: 'reset.index',
    component: () => import('@/pages/Auth/ResetPass'),

    meta: {
      title: 'Reset',
      auth: false
    }
  },

  {
    path: '/deeplink',
    name: 'deeplink.index',
    component: () => import('@/pages/Deeplink/Index'),

    meta: {
      auth: false,
      title: 'EWA'
    }
  },

  // Library
  {
    path: '/library/',
    name: 'library.index',
    component: () => import('@/pages/Library/Index'),
    beforeEnter (to, from, next) {
      registerModule('books', 'books').then(() => next())
    },

    meta: {
      auth: true,
      title: 'Library',
      experiment_layer: 'class'
    },
    children: [
      {
        name: 'library.start',
        path: '',
        component: () => import('@/pages/Library/Library')
      },
      {
        path: 'search',
        name: 'library.search',
        component: () => import('@/pages/Library/SearchResult'),

        meta: {
          auth: true,
          title: 'Search',
          experiment_layer: 'class'
        }
      },
      {
        path: 'card/:resourceId',
        name: 'card.index',
        component: () => import('@/pages/Library/Card'),

        meta: {
          auth: true,
          title: 'Card',
          experiment_layer: 'class'
        }
      },
      {
        path: 'reader/:resourceId/book',
        name: 'reader.book',
        component: () => import('@/pages/Library/Readers/Book'),

        // If the user needs to be authenticated to view this page.
        meta: {
          auth: true,
          experiment_layer: 'class',
          contentType: 'book'
        }
      },
      {
        path: 'reader/:resourceId/article',
        name: 'reader.article',
        component: () => import('@/pages/Library/Readers/Book'),

        // If the user needs to be authenticated to view this page.
        meta: {
          auth: true,
          experiment_layer: 'class',
          contentType: 'article'
        }
      },
      {
        path: 'reader/:resourceId/dictor',
        name: 'reader.dictor',
        component: () => import('@/pages/Library/Readers/BundleBook'),

        meta: {
          auth: true,
          experiment_layer: 'class',
          contentType: 'dictor'
        }
      }
    ]
  },

  {
    path: '/ai-dialogs/',
    name: 'ai-dialogs.index',
    component: () => import('@/pages/Ai/Index'),
    redirect: { name: 'ai-dialogs.start' },
    meta: {
      auth: true,
      experiment_layer: 'class'
    },
    children: [
      {
        path: '',
        name: 'ai-dialogs.start',
        component: () => import('@/pages/Ai/List')
      },
      {
        name: 'ai-dialogs.dialog',
        path: ':dialogId',
        component: () => import('@/pages/Ai/Dialog')
      }
    ]
  },

  ...checkouts,

  ...landings,

  {
    path: '/email-verification-success',
    name: 'emailVerificationSuccess.index',
    component: () => import('@/pages/EmailVerificationSuccess/Index'),

    meta: {
      auth: false,
      type: 'email_verification_success'
    }
  },

  {
    path: '/teachers',
    redirect: '/'
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/pages/NotFound/Index')
  },

  {
    path: '/retain',
    name: 'retain.index',
    component: () => import('@/pages/Retain/Index')
  }
]

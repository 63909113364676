export const CRM_EXPERIMENT_PAGES = [
  '/winter-6-6',
  '/winter-3-9',
  '/winter-12-12',
  '/winter-70',
  '/spring-sale-3-9',
  '/spring-sale-70',
  '/spring-sale-6-6',
  '/spring-sale-50',
  '/spring-sale-12-12',
  '/autumn-3-9',
  '/autumn-70',
  '/autumn-6-6',
  '/autumn-12-12',
  '/summer-3-9-control',
  '/summer-70-control',
  '/summer-6-6-control',
  '/summer-12-12-control',
  '/onboarding-6-6',
  '/onboarding-3-9',
  '/onboarding-70',
  '/onboarding-12-12',
  '/unlimited-2checkout'
]

export const CRM_EXPERIMENT_TIERS = ['t1', 't2', 't3', 't4', 't5']
